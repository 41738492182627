body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glassEffect {
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);	
	border-radius: 5px;
	position: relative;
	z-index: 1;
	background: inherit;
	overflow: hidden;
}

.glassEffect::before {
  content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.scene-container {
    position: relative!important;
    width: 100%!important;
    height: 100vh!important;
}

canvas{
	margin: auto;
}

.error_modal {
	width: 100vw!important;
}

@media all and (max-width: 599px) { 
	.error_modal,.login_modal {
		width: 100vw!important;
	}
}
@media all and (min-width: 600px) and (max-width: 899px) { 
	.error_modal,.login_modal {
		width: 80vw!important;
	}
}

@media all and (min-width:900px) and (max-width: 1199px) {
	.error_modal,.login_modal {
		width: 50vw!important;
	}
}

@media all and (min-width:1200px) {
	.error_modal,.login_modal {
		width: 40vw!important;
	}
}